import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// TODO: Review this implementation
const analytics = (amplitudeKey)=>({
  init: function init(user) {
    const isImpersonating = user?.impersonated_nav_info?.show;
    const userId = isImpersonating
      ? user?.pendo_setup?.visitor?.id
      : user?.sub?.split(":").at(-1);

    // Sample Rate 0.1 = 10%, 1 = 100%
    // https://amplitude.com/docs/session-replay/session-replay-standalone-sdk
    // const sampleRate = import.meta.env.PROD ? 0.1 : 100;
    const sessionReplayTracking = sessionReplayPlugin({ sampleRate : 0.1 });
    amplitude.add(sessionReplayTracking);

    amplitude.init(amplitudeKey, userId, { defaultTracking: true, sessions: true });
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("client_id", user?.client);
    identifyEvent.set("announcements_sms_status", userHasSmsAnnouncementsEnabled(user));
    identifyEvent.set("user_type", getRoomsUserType(user));
    amplitude.identify(identifyEvent);
  },
  track: amplitude.track.bind(amplitude),
});

const userHasSmsAnnouncementsEnabled = (user) => {
  return !!user.notification_preferences?.find(np => np.service === "rooms-announcements" && np.channel === "sms" && np.enabled);
}

const getRoomsUserType = (user) => {
  return user.roles?.rooms || "staff";
}

export default (object) => {
  const amplitudeKey = document.getElementById("amplitude-key").value;
  if (!amplitudeKey) return;
  object.config.globalProperties.$analytics = analytics(amplitudeKey);
};
